import React, { useEffect, useState } from "react";
import styles from "./Section7.module.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./../../../api"; // Update the path to your axiosInstance file

const Section7 = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Fetch data from the API
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axiosInstance.get(process.env.REACT_APP_CAKECOMPANY_CATEGORY);

                // Check if response is successful
                if (response.data.responseStatus) {
                    setCategories(response.data.responseObject);
                } else {
                    setError("Failed to fetch categories.");
                }
            } catch (err) {
                setError("An error occurred while fetching categories.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    // Handle click on an image
    const handleImageClick = (category) => {
        navigate(`/order-now?category=${category}`);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <section className={styles.sectionSeven}>
            <h2 className={styles.sectionSevenTitle}>Explore Our Special Cakes</h2>
            <div className={styles.imageContainer}>
                {categories.map((category) => (
                    <div
                        key={category.id}
                        className={styles.imageSlide}
                        onClick={() => handleImageClick(category.category)}
                    >
                        <img
                            src={category.imageUrl}
                            alt={category.category}
                            className={styles.image}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Section7;
